@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.row {
  display: flex;
  gap: 4px;
  @include mix.row;
  flex-direction: column;

  &Title {
    @include mix.subheading;
    display: none;
  }
}

.labels {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.container {
  position: relative;
}

div.dropdown {
  width: 340px;
  flex-wrap: wrap;
  flex-direction: row;

  @include mix.vp-767 {
    width: 100%;
  }
}

div.addLabel {
  color: var.$white;
}

.newLabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;
}

.input {
  @include mix.text(20, 500, 24);

  @include mix.vp-1024 {
    @include mix.text(18, 500, 20);
  }
}

.check {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
